import { Routes } from '@angular/router';

export const urlRoutes = {
    HOME: '',
    CONTACTS : 'contatti',
    PRIVACY : 'privacy-policy',
    COOKIE:  'cookie-policy',
    THEMES : 'tematiche',
    ARCHIVE : 'archivio',
    ARCHIVE_EDITION : 'archivio-edizioni',
    DETAIL_EDITION : 'edizione', //unused
    PAGE_404 : 'page-404',
  }

export const routes: Routes = [
    { path: urlRoutes.HOME, loadComponent: () => import('./pages/home/home.component'), },
    { path: urlRoutes.CONTACTS, loadComponent: () => import('./pages/contacts/contacts.component') },
    { path: urlRoutes.PRIVACY, loadComponent: () => import('./pages/privacy-policy.component') },
    { path: urlRoutes.COOKIE, loadComponent: () => import('./pages/cookie-policy.component') },
    { path: `${urlRoutes.ARCHIVE}/:page`, loadComponent: () => import('./pages/archive/archive.component') },
    { path: urlRoutes.ARCHIVE, redirectTo: 'archivio/1', pathMatch: 'full' },
    { path: urlRoutes.ARCHIVE_EDITION, loadComponent: () => import('./pages/archive-edition/archive-edition.component') },
    { path: `${urlRoutes.ARCHIVE_EDITION}/:magazineSlug`, loadComponent: () => import('./pages/archive-edition/archive-edition.component') },
    
    { path: `${urlRoutes.ARCHIVE}/:theme/:page`, loadComponent: () => import('./pages/themes/themes.component') },
    { path: `${urlRoutes.ARCHIVE}/:theme/:category/:page`, loadComponent: () => import('./pages/themes/themes.component') },

    { path: `:magazineSlug/:number`, loadComponent: () => import('./pages/detail-edition/detail-edition.component') },
    { path: ':magazineSlug/:number/:category/:slug', loadComponent: () => import('./pages/detail-article/detail-article.component') },
    { path: urlRoutes.PAGE_404, loadComponent: () => import('./pages/page404/page404.component') },
    { path: '**', pathMatch: 'full', loadComponent: () => import('./pages/page404/page404.component') }
];
